
@import '../../_conf.scss';

.b2button-btn:hover{
    background-color: #7499a5!important;
}

.b2button-btn:active{
    background-color: #4d6a74!important;
}


input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;   
  }
  
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
 
  }

  .bcombo-btn-extra{
    color:rgb(113, 139, 160);
    margin-Right:5px;
    cursor:pointer;
  }
  

  .bcombo-btn-extra:hover{
    color: rgb(29, 77, 166);
  }
  
  .number-input {
    border: 0;
    margin: 0;
    min-width: 70px;
   
    background-color: white;
    display: flex;
    flex-direction: column;

    border-bottom: 1px solid rgb(202, 202, 202);   
  }

  .number-input-interno{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 4px;

  }
  
  .number-input-interno,
  .number-input-interno * {
    box-sizing: border-box;  
  }
  
  .number-input-interno button {
    outline:none;
    -webkit-appearance: none;
    background-color: #e4e4e4;
    min-width: 18px;
    width: 18px;
    min-height: 18px;
    height: 18px;
    cursor: pointer;
    margin: 0 !important;
    padding: 0;
    box-shadow: 0px 0px 1px #404040;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 50%;    

    font-size: 16px;
    font-weight: 500;
    font-family: "Lucida Console";
    text-align: center;
    color: #424242;
}
  
  
  .number-input-interno input[type=number] {
    font-family: sans-serif;
    min-width: 20px;
    width: 100%;
    border: none;
    border-width: 0 2px;
    font-size: 15px;
    text-align: center;
  }


/* -------------------------------------------------------------------------------------- */


.b-toggle-btn{
    background-color: rgba(255, 255, 255, 0);
    border-radius: 7px;
    height:100%; 
    width:100%;
    display:flex;
    align-Items:center;
    justify-Content:center;
    padding-left: 6px;
    padding-right: 6px;    
    cursor: pointer;
}

.b-toggle-btn:hover{
    outline: 1px solid rgb(182, 180, 204);

}

.b-toggle-btn:active{
    outline: 1px solid rgb(105, 101, 154);

}

.b-toggle-sel{
    background-color: rgb(196, 218, 252);   
    outline: 1px solid gray;
    color: black!important;
}

/* -------------------------------------------------------------------------------------- */


.bediticon-content{
    border: 1px solid rgb(199, 199, 199);
    background-color: white;
    height: 45px;
    width: 180px;
    min-width: 180px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;
    z-index: 0;
}

.bediticon-picker{
    position:fixed;
}


/* --------------------------------------------------------------------------------------- */
/* BConetext */

.bcontext-fundo{
    position: fixed;
    z-index: 99999;
    width: auto;
    min-width: 250px;
    height: auto;
    background-color: rgba(255, 255, 255, 0.945);
    border: 1px solid rgb(195, 195, 195);
    top: 25px;
    left: 25px;
    border-radius: 10px;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
    opacity: 0;

    animation-name: anim-bcontext;
    animation-duration: .40s;
    animation-fill-mode: forwards; 
}



@keyframes anim-bcontext {
    from {opacity: 0;} 
    to{opacity: 1;}

}

.bcontext-topo{
    /*border: 1px solid rgb(205, 18, 18);*/
    width: 100%;
    height: 38px;
    min-height: 38px;
    box-sizing: border-box;
}

.bcontext-itens{
    /*border: 1px solid rgb(205, 18, 18);*/
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    animation-name: anim-item;
    animation-duration: .50s;
    animation-fill-mode: forwards; 
}



@keyframes anim-item {
    from {opacity: 0;} 
    to{opacity: 1;}

}

.bcontext-item{
    
    /*border: 1px solid rgb(43, 18, 205);*/
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    
    font-size: 13px;
    cursor: pointer;
    border-radius: 7px;
    
    overflow: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;

    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
   
}

.bcontext-item:hover{
    background-color: #e7f2f8db;
}

.bcontext-disabled{
    cursor: default!important;
    background-color: rgba(255, 255, 255, 0.945)!important;
    color:rgb(161, 172, 181)!important;
}

.bcontext-disabled:hover{
    background-color: rgba(255, 255, 255, 0.945)!important;
}

.bcontext-ite-topo{
    
    /*border: 1px solid rgb(43, 18, 205);*/
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
    
    font-size: 13px;
    cursor: pointer;
    border-radius: 7px;
    
    overflow: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;

    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.bcontext-ite-topo:hover{

    outline: 1px solid rgb(172, 172, 172);
    background-color: #e7f2f8db;

}

.bcontext-separador{
    height: 5px;
    border-bottom: 1px solid rgb(212, 222, 230);
    margin-bottom: 5px;
}

@media screen and (max-width: 620px){
    .bcontext-itens{
        max-height: 400px;
        overflow: auto;
    }
}



/* --------------------------------------------------------------------------------------- */

.bcirculo{
    background-color: aqua;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;

}
.bcirculo_a{
    background-color: aqua;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: -5px;

}
.bcirculo_b{
    background-color: aqua;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 0px;

}

.bcombo2{

 


}

.b-button{
     /* 
    padding-left: 4px;
    padding-right: 5px;
    */
    padding-left: 11px;
    padding-right: 11px;

    min-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    /* border-radius: 3px; */
    border-radius: 20px;
    box-sizing: border-box;
   /* box-shadow: 1px 1px 7px -3px rgba(26, 26, 26, 0.6);*/
    transition: box-shadow 0.2s ease-in-out;
    transition: background-color 0.25s ease-in-out;

}






 



  .b-anima {
    position: relative;
    width: 600px;
    height: 20px;
    overflow: hidden;
  }
  
  .b-anima::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #1500ff9e;
    animation: aanima 0.6s alternate;
  }
  
  @keyframes aanima {
    0% {
      left: -100%;
    }
    100% {
      left: 0%;
    }
  }









.b-button-borda{
    animation: b_pulse 2s infinite;
}

  @keyframes b_pulse {
    0% {
        box-shadow: 0px 0px 0px 0 rgb(0, 255, 34);
    }
    70% {
        box-shadow: 0px 1px 26px 0 rgb(255, 136, 0);
    }
    100% {
        box-shadow: 0px 0px 0px 0 rgb(0, 119, 255);
    }
  }

  

.b-button-icon{
    font-size: 18px;
    margin-left: 0px;
  /*  margin-right: 2px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.b-button-icon-end{
    font-size: 18px;
    margin-left: 0px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.b-button-texto{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

}

.b-button svg{
    margin-left: 2px;  
    margin-right: 2px;  
}

.b-button:focus{
    /* box-shadow: 1px 1px 14px -1px rgb(0, 89, 214); */
}

.b-normal:hover{

    box-shadow: 1px 1px 14px -3px rgba(26, 26, 26, 0.8);
}

.b-normal:active{
    background-color: rgb(185, 215, 234) !important;
    box-shadow: 1px 1px 25px -2px rgba(26, 26, 26, 0.9);
}

.b-normal:focus{
    border: 1px solid rgb(0, 82, 246) !important;
    box-shadow: 2px 2px 15px -2px rgba(62, 142, 246, 0.9), inset 0px 0px 0px 2px rgb(179, 187, 201);
}


.b-button:disabled{
    box-shadow: none;
    color:rgba(111, 111, 111, 0.8)!important;
    background-color: rgba(133, 133, 133, 0.2) !important;
    border: 0px !important;
  
    
}



.b-button-tx-auto-hide .txtx{
   display: none;
}

.b-button-tx-auto-hide:hover .txtx{   
    display: block;
   
}




.bedit-item-title{
    top: unset;

    bottom: 75%;
    overflow: initial;
    white-space: initial;
    margin-bottom: 2px;
    font-size: 12px;
    transform: unset !important;
    min-height: 18px;
    max-height: 18px;
    height: 18px;
    line-height: 1.21;
    /*min-width: 20em;*/
    width: auto;
    white-space: nowrap;
    margin-left: 0;
    color: rgba(0, 0, 0, 0.679);
    z-index: 1;
    

    padding-left: 2px;
    padding-right: 2px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    


    background-color: transparent;

    cursor: text;
}

/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */


.bedit-model-1::placeholder{
    color: rgb(166, 184, 196);
    font-weight: 0;
}

.bedit-color-place-holder::placeholder {
    color: inherit !important;
    font-size: inherit !important;
  }

.bedit-fundo-model-1{
    border: none;
    background-color: white;
    border: 1px solid  rgb(225, 225, 225);
    border-bottom: 1px solid rgb(180, 180, 180);
    border-radius: 3px;
    padding: 1px;
    display: flex;
    flex-direction: column;
}
.bedit-fundo-model-2{
    border: 1px solid  rgb(202, 202, 202);
    border-radius: 4px;
    padding: 1px;
    display: flex;
    flex-direction: column;
    margin: 1px;
}
.bedit-fundo-model-3{
    border: none;
    display: flex;
    flex-direction: column;
}
.bedit-fundo-model-4{
    border: none;
    border-bottom: 1px solid rgb(202, 202, 202);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bedit-model-1{
   
    width: 100%;
    height: 100%;
    padding-left: 6px;
    padding-right: 6px;
    border: none; 
    border-width: 0; 
    box-shadow: none;
    
    display: flex;
    align-items: center;
}



.bedit-model-2{
    width: 100%;
    height: 100%;
    border: none; 
    border-width: 0; 
    box-shadow: none;
    padding-left: 6px;
    padding-right: 6px;
   
    display: flex;
    align-items: center;
}

.bedit-model-3{
    width: 100%;
    height: 100%;
    padding-left: 6px;
    padding-right: 6px;
    border: none; 
    border-width: 0; 
    box-shadow: none;
    display: flex;
    align-items: center;
}

.bedit-center{
    text-align: center;
}

/* PULSE DO BMolder */



 .bmolder:has(.bmolder-input:focus){
    outline: 2px solid rgb(0, 179, 255);
    border-color: rgb(0, 122, 174);

      box-shadow: 0 0 0 0 rgb(52, 172, 224);
      /*  transform: scale(1); */
      animation: pulse_bmolder 2s infinite;
  }

  .bm-force-pulse{
    outline: 2px solid rgb(0, 179, 255);
    border-color: rgb(0, 122, 174);

      box-shadow: 0 0 0 0 rgb(52, 172, 224);
      /*  transform: scale(1); */
      animation: pulse_bmolder 2s infinite;
  }

  @keyframes pulse_bmolder {
    0% {
     /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }
  
    50% {
    /*  transform: scale(1); */
      box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
    }
  
    100% {
    /*  transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
  }


/* ----------------------------------------------------------------------- */

.bspin-content{
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
    
}

.bspin-box{
    position: absolute;
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;

   /* background-color: #f5f5f5c1; */
   background: rgb(174,225,238);
   background: radial-gradient(circle, rgb(117, 181, 233) 0%, rgba(210, 230, 255, 0.608) 70%);
   z-index: 9999;
    top: 0;
    left: 0;
    border-radius: 4px;
}

.bspin-texto{
    margin-top: 12px;
    font-size: 18px;
    font-weight: 500;
}

.bspin-center{
    position: fixed;
    height: 100px;
    width: 200px;
    /*background-color: red;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -215%);

}


/* ----------------------------------------------------------------------- */
/*  SPIN INTER DIV  background-color: #cbcbcbc1; */

.bspin-intern-box{
    position: fixed;
    height: 250px;
    min-height: 250px;
    width: 250px;
    min-width: 250px;
    
    background: rgb(174,225,238);
    background: radial-gradient(circle, rgb(117, 181, 233) 0%, rgba(210, 230, 255, 0.608) 70%);

    top: 0;
    left: 0;
    z-index: 999;

}

.bspin-intern-texto{
    margin-top: 12px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
}

.bspin-intern-center{
    position: relative;
    height: 100px;
    width: 350px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

/* ----------------------------------------------------------------------- */
/*  SPIN DIV */

.bspin-div-div{
    position: relative;
}

.bspin-div-tx{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.bspin-div-box{

    position: absolute;
    height: 100%;
    width: 100%;
  
    
   /* background: rgb(60, 66, 68); */
    background: radial-gradient(circle, rgb(117, 181, 233) 0%, rgba(210, 230, 255, 0.608) 70%);

    top: inherit;
    left: 0; //inherit;
    z-index: 999;

    
    animation: spin_anim 0.4s ease-out;
}

@keyframes spin_anim { 
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }

  }
  


.bspin-div-texto{
    margin-top: 12px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
}

.bspin-div-center{
   /* position: relative;*/
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
   

   /* 
    gap: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
*/
}

.bspin-div-info{
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

}

/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */
/* -------------------------- FILTROS ------------------------------------ */

.box-filtros{
    height: 100%;
    
    width: 200px;
    //max-width: 200px;

    padding: 5px;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
}
              
.box-filtros-aplicados{
    height: 100px;
  
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 5px;


    display: flex;
    gap: 6px;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}

.box-filtros-lista{
   
    height: 100%;
    width: 100%;
}

.box-filtros-lista-item-box{

    width: 100%;
    height: 65px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin-top: 8px;
}

.box-filtros-lista-item-box .titulo{
    background-color:rgb(208, 227, 242);
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 5px;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.box-filtros-lista-item-box .content{
    background-color: rgb(226, 242, 255);
    width: 100%;
    height: 100%;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.box-filtros-lista-item-box button{
    margin-left: 4px;

    
}


.box-filtros-tag{
 background-color: white;
 border: 1px solid black;
 display: flex;
 align-items: center;
 justify-content: space-between;
 gap: 12px;
 padding: 5px;
 height: 28px;

 .tag-x{
    font-weight: 600;
    cursor: pointer;
 }
}

/* -------------------------------------------------------------- */

.bedit-center::-webkit-outer-spin-button,
.bedit-center::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.bedit-direita{
    text-align: right;
    padding-right: 6px;
}


.bedit-item-title-lateral{
    cursor: pointer;
    bottom: 75%;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.21;
    margin-left: 0;
    color: rgba(0, 0, 0, 0.679);
    z-index: 1;
}


.bedit-row-check{
   /* border: 1px solid red;*/
    width: calc(50% - 15px);
    padding: 0px;
    height: 50px;
    

    display: flex;
  
    align-items: center;  
}

.bedit-row-check input{
    min-width: 22px;
}

.bedit-row-check-small{
    margin-left: 8px;
    margin-top: 2px;
    margin-right: 3px;

   /* border: 1px solid red;*/
    width: calc(20% + 5px);
    padding: 0px;
    height: 50px;

    display: flex;
  
    align-items: center;  
}

.bedit-row-check-small3{
    margin-left: 1px;
    margin-top: 1px;
    margin-right: 1px;

   /* border: 1px solid red;*/
    padding: 0px;


    display: flex;
    align-items: center;  
}

.bedit-row-item-lado{
    gap: 8px;

    margin-left: 3px;
    margin-right: 3px;

   /* border: 1px solid red;*/
    width: calc(50% + 15px);
    padding: 0px;
    height: auto;

    display: flex;
    align-items: center;
    
    justify-content: center;
   
    white-space: nowrap;
    p{
        margin-top: 4px;
    }
}

.bedit-check{
    
    width: 22px;
    height: 26px;
    border: none;
    border-bottom: 1px solid rgb(255, 0, 0);

}


.btec-list-linha{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-height: 56px;
    padding-left: 16px;
    padding-right: 16px;
    align-self: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(209, 209, 209);
    
}

.btec-list-col{
    flex: 1;
    align-items: center;
    display: flex;
    min-width: 10em;
}

.btec-menu-context{

    position: fixed;
    height: 250px;
    width: 185px;
    left: 100px;
    background-color: #FFFFFF;
    z-index: 99;

    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(64, 64, 64, 0.15);
    flex-direction: column;
}

.btec-menu-context-list{
    border-top: 1px solid #ddd;
    list-style-type: none;
    margin: 0;
    display: block;
    width: 100%;
    padding: 8px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
   
}

.btec-menu-context-item{
    position: relative;
    display: list-item;
    text-align: -webkit-match-parent;
    list-style-type: none;

} 

.btec-menu-context-item-btn{
    outline: none;
    border: 0;
    padding: 8px 8px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #FFFFFF;
  
}

.btec-menu-context-item-btn:hover {
    background-color: #e8e8e8;
  }

.btec-display-grid{
    height: 40px;
    display: grid;
    gap: 20px;
    width: auto;
    margin-bottom: 5px;
}


.btec-display-valor-box{

    /*border: 1px solid red;    */
    display: flex;
    min-width: 75px;
    justify-items: left;
    height: 46px;
    padding-right: 5px;
    background-color: rgb(212, 226, 236);
    color:rgb(28, 35, 46);


    .btec-display-valor-tag{
       height: auto;
       width: 4px;
       min-width: 4px;
       opacity: 0.65;
    }

    .btec-display-valor-content{
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        line-height: 14px;
        .btec-display-valor-titulo{
            margin-left: 5px;
            margin-top: -4px;
            font-size: 11px;
            overflow: hidden;
        }

        .btec-display-valor-valor{
            margin-left: 5px;
            font-size: 16px;
            font-weight: 600;
            font-family: 'Segoe UI';

        }
    }

}

/* ----------------- */


.btec-display-comparador-box{

    /*border: 1px solid red;    */
    display: flex;
    min-width: 250px;
    justify-items: left;

    background-color: rgb(212, 226, 236);
    color:rgb(28, 35, 46);
    border: 1px solid rgb(153, 182, 190);
  


    .btec-display-comparador-tag{
       height: auto;
       width: 4px;
       min-width: 4px;
       opacity: 0.65;
    }

    .btec-display-comparador-content{
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        line-height: 14px;
      
   
        .btec-display-comparador-titulo{
            margin-left: 3px;
            font-size: 11px;
            overflow: hidden;
            white-space: nowrap;
        }

        .btec-display-comparador-valor{
            margin-left: 3px;
            font-size: 15px;
            font-weight: 600;
            font-family: 'Segoe UI';
            overflow: hidden;
            white-space: nowrap;

        }
    }
}

.tec-display-comparador-content:first-child{
    border-radius: 7px;
}

/* SELECTER */
.selecter_content{
    box-sizing: border-box;
    padding: 0px;
    box-shadow: 0 8px 16px 0 rgb(187, 187, 187);
    background: #fff;

    width: 100%;
    height: auto;
    border-radius: 6px;

    
}

.selecter_borda{
    animation: pulse 2s infinite;
}

  @keyframes pulse {
    0% {
        box-shadow: 0px 0px 0px 0 rgb(0, 255, 34);
    }
    70% {
        box-shadow: 0px 1px 26px 0 rgb(255, 136, 0);
    }
    100% {
        box-shadow: 0px 0px 0px 0 rgb(0, 119, 255);
    }
  }

.selecter_btn{
    padding: 6px;
    padding-left: 12px;
    box-sizing: border-box;
    min-height: 50px;
    
    &:not(.selecter_selected):hover{
      cursor: pointer;     
      background-color: rgba(80, 179, 255, 0.131);  
    }

}

.selecter_selected{
    background-color: rgba(29, 132, 25, 0.357);
    cursor: pointer; 
}


.selecter_btn:first-of-type{
    border-start-start-radius: 6px;
    border-start-end-radius: 6px;
}

.selecter_btn:last-of-type{
    border-end-start-radius: 6px;
    border-end-end-radius: 6px;
}

.selecter_titulo{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
}

.selecter_bottom{
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 1px;
}

/* ********* */