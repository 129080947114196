/* Firefox */
@media (prefers-color-scheme: dark) {
  :root {
      color-scheme: light;
  }
}

/* Chrome */
@media (forced-colors: active) {
  :root {
      color-scheme: light;
  }
}


body {
  overscroll-behavior: contain;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 14px sans-serif;
  background-color: #e2e2e2;
overflow: auto;
  /*background: linear-gradient(-25deg, #dae2e6  30%, #EEEEEE 70%);*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print{
  .noprint{
      display: none !important;
  }
  .print{
    margin: 0!important;
    padding: 0!important;
  }
}




.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.selecionavel{
  -webkit-user-select: text!important; /* Safari */
  -ms-user-select: text!important; /* IE 10 and IE 11 */
  user-select: text!important; /* Standard syntax */
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  /* Bloquear seleção em tudo */
  -webkit-user-select: text; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  /**/
  -khtml-user-select: none; /* Konqueror HTML */
  -webkit-touch-callout: text; /* iOS Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  /**/


}

*:focus{
  outline: 0;
}

input:disabled{
  background-color: rgb(242, 242, 242);
}

html, body, #root{
  height: 100%;

}

a{
  text-decoration: none;
  color: inherit;
}

a:focus, a:hover, a:active{
  color: #0b497c;
}

a:visited{
  color: inherit;
}


ul{
  list-style: none;
}

button{
  cursor: pointer;
}

.app-box{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
 /* background-color: #857c83; */

}

.app-content{
  margin-left:0; 

   /* overflow: hidden; */
  /* border: 2px solid rgb(255, 0, 0);*/

  /*  */
  min-height: calc(100% - 44px)!important;
  max-height: calc(100% - 44px)!important;

  overflow: auto;
  width: 100%;

  /* border: 2px solid rgb(255, 0, 0);*/

  box-sizing: border-box;
 
}

.app-padding-com{
  padding-top: 14px;
  padding-left: 58px;
  padding-right: 58px;
}

.app-padding-sem{
  padding: 0;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}



/* SCROLL BAR */

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(179, 179, 179);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f7bba;
}


@media screen and (max-width: 1070px){
  /*
  margin-left: 220px;
  padding: 1px 14px;
  */


  .app-content{
   /* margin-left: 50px; */
    margin:0px;
    
    
    height: 100%;
   /* overflow: hidden; */

    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  
}

.icon-anim {
  transform: scale(0.5);
  animation: aanimm 1s infinite alternate; 
}

@keyframes aanimm {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}