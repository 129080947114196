.amarelo{
    color: red;
}

.dash_fundo{
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 100%;
    padding: 25px;
    overflow: auto;


}

.dash_sub_box{
    margin-bottom: 10px;
    border: 1px solid rgb(207, 207, 207);
    background-color: rgb(255, 253, 253);
    border-radius: 3px;
    min-height: 67px;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 12px;

    display: none;
    justify-content: center;
    overflow: auto;


}

.dash_sub_box::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

/* ------------------------------------------------ */

.dash_box_news{
    border: 1px solid rgb(207, 207, 207);
    background-color: rgb(255, 255, 255);
    border-radius: 3px;
    width: 400px;
 
 
    margin-right: 15px;

}

.dash_news_fundo{
    border-bottom: 1px solid rgb(234, 234, 234);
    height: auto;
    width: 100%;
    padding: 5px;
  /*
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0px;*/

}

.dash_news_item{
   /* background-color: rgb(255, 255, 255); */
   background-color: white;

    height: auto;
    width: 100%;

    display: flex;
}

.dash_news_img{
    /* background-color: rgb(255, 255, 255); */
    /* border: 1px solid rgb(35, 148, 0);*/
     height: auto;
     width: 70px;

     border-radius: 8px;
     margin-left: -2px;
     justify-content: center;
     align-items: center;
     display: flex;
     color: #333;
     cursor: pointer;
 }

 .dash_news_data{
     font-size: 10px;
     margin-left: 2px;
     margin-bottom: 2px;
     text-align: end;
     font-family:Verdana, Geneva, Tahoma, sans-serif; 

     width: 100px;
     min-width: 100px;
     max-width: 100px;
     color: #000000;
     font-weight: 400;
 }

 .dash_news_infos{
    /* background-color: rgb(255, 255, 255); */
     width: 100%;
    /* border: 1px solid rgb(40, 18, 238); */
     height: auto;
     border-radius: 8px;
     padding: 5px;
    
 }

 .dash_news_title{
     width: 100%;
    display: flex;
    justify-content: space-between;
     margin-top: 2px;
     margin-bottom: 4px;
     font-weight: 600;
     font-size: 12px;
 }
 
 .dash_news_body{
    width: 100%;
    margin-bottom: 2px;
}


 /*------------------------------------------- */

.dash_box_dados{
    border: 1px solid rgb(207, 207, 207);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;



}

.dash_box_title{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: rgb(255, 255, 255);
    height: 27px;
    border-bottom: 1px solid rgb(226, 226, 226);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dash_box_content{
    min-height: 250px;
    min-width: 350px;
}

@media screen and (max-width: 1000px){

    .dash_fundo{

        height: 100% ;
        margin-left: 0px;
        display: flex;
        justify-content: center;

        width: calc( 100% );
       /* border: 1px solid red; */
        padding: 25px;
    }
    

}

@media screen and (max-width: 800px){
    .dash_sub_box{
        display: flex;
    }
    .dash_fundo{

        height: 100%;
        margin-left: 0px;
        display: block;
        flex-direction: column;
        
        width: 100%;
       /* border: 1px solid red; */
        padding: 25px;
    }
    
    .dash_box_news{
        margin-top: 0px;   
        height: 500px;
        max-height: 500px;
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;
    }

    .dash_box_dados{
        margin-top: 0px;   
        height: 600px;
        max-height: 600px;
        width: 100%;
        margin-bottom: 20px;
    }

}

/* -------------------------------------------- */

.dash_infos_lista{

    padding: 8px;

}

.dash_infos_item_fundo{
    display: flex;
    width: 100%;
    height: auto;
    padding: 5px;
}

.dash_infos_item{
    
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-family: Helvetica;
    color: #313131;
}


.dash_infos_titulo{

    width: 300px;
    max-width: 300px;

}
                                
.dash_infos_qtd{

    width: 60px;
    max-width: 60px;
    min-width: 60px;
    text-align: center;
}
                       
.dash_infos_valor{
    text-align: right;
    width: 100px;
    max-width: 100px;
    min-width: 100px;
}

/* -------------------------------------------- */

@media screen and (max-width: 520px){
    .dash_sub_box{
        justify-content: start;
    }
}

@media screen and (max-width: 600px){


    .dash_fundo{

        padding: 5px;
    }
    
    .dash_box_news{
        margin-top: 0px;   
        height: auto;
        max-height: 500px;
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;
        overflow: auto;
    }

    .dash_box_content{
        min-height: auto;
        min-width: auto;

    }
    

    .dash_box_dados{
        margin-top: 0px;   
        height: auto;
        min-height: 172px;
        max-height: none;
        width: 100%;
        margin-bottom: 20px;
    }

}



/************************************/

.copos-fundo{
    height: 150px;
    width: 90%;
    /*border: 1px solid red;*/
    margin: 10px;
    margin-left: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;

}

.copos-fundo button:focus{
    transform: scale(1.05);
}

.copo{
    height: 100%;
    width: 50px;

    /*border: 1px solid rgb(0, 0, 0);*/
    padding: 0;
    margin-right: 10px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: end;
   /* border-radius: calc(0.5 * 50px);*/
    border-radius: 3.75rem;

    box-shadow: 0 1rem 1.25rem 0 rgba(22, 74, 195, 0.44),          0 -0.25rem 1.5rem rgba(111, 15, 155, 0.179) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.337) inset,  0 0.25rem 0.5rem 0 rgba(180, 70, 207, 0.371) inset;
}

.copo-ok{
    border: 5px solid rgb(255, 255, 255);
    background-color: rgb(255, 255, 255);
    border-radius: calc(0.5 * 50px);
}

.copo-parc{
  /*  border: 1px solid rgb(0, 182, 143);*/
    background-color: aqua;
    width: 100%;


    /*border: 1px solid #012880; */




}

.copo-cores-2{
    height: 50%;
}

.copo-cores-3{
    height: 33.33%;
}

.copo-cores-4{
    height: 25%;
}

.copo-cores-5{
    height: 20%;
}

.copo-cores-6{
    height: 16.66%;
}

.copo-cores-7{
    height: 14.28%;
}

.copo-cores-8{
    height: 12.50%;
}


.copo-parc:first-child{
    /*  border: 1px solid rgb(0, 182, 143);*/
      border-end-end-radius: calc(0.5 * 50px);
      border-end-start-radius: calc(0.5 * 50px);
  }

  .copo-cheio .copo-parc:last-child{
    /*  border: 1px solid rgb(0, 182, 143);*/
      border-start-start-radius: calc(0.5 * 50px);
      border-start-end-radius: calc(0.5 * 50px);
  }

  .copo-ok .copo-parc:last-child{
    /*  border: 1px solid rgb(0, 182, 143);*/
      border-start-start-radius: calc(0.5 * 50px);
      border-start-end-radius: calc(0.5 * 50px);
  }


/************************************/