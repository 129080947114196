.pontoqr-marca{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: -1;
    /*
    background-size: 65px;
    opacity: .1;
    background:url("../../assets/lobo-beta-2-180.png")   repeat   ; */
    background:url("../../assets/BeiTec2.png") space; 
    background-size: 155px;


    opacity: .15;
}

.pontoqr-content{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    

}

.pontoqr-infos-hor{
    height: 100%;
    display: flex;
}

.pontoqr-box{
   margin-top: 8px;
   background-color: rgba(255, 255, 255, 0.7);
   height: 500px;
   width: auto;
   display: flex;
   padding: 5px;
   border-radius: 10px;
}


.pontoqr-boxlat-a{

    width: 100%;
    border-right: 1px solid rgba(170, 199, 219, 0.88);
    display: flex;
    flex-direction: column;
}

.pontoqr-boxlat-b{

    background-color: rgba(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.pontoqr-infos{
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.pontoqr-content-btns{
    width: 100%;
    min-height: 140px;
    padding: 2px;
    padding-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;
}

.pontoqr-btns{
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: rgba(216, 216, 216, 0.496);
    border: 1px solid rgb(188, 188, 188);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7px;

}

.pontoqr-content-mob-btns{
    width: 100%;
    min-height: 140px;
    padding: 10px;
    padding-right: 17px;
    margin-top: 15px;
}

.pontoqr-icons{
    display: flex;
    gap: 10px;
}

.pontoqr-interno{
    width: 400px;
}

  
  .flash-foto{
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    animation: flash 0.5s linear 1 forwards;
  }
  
  @keyframes flash {    
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
 

@media screen and (max-width: 500px){

    .pontoqr-box{
        margin-top: -28px;
        width: 100%;

    }

    .pontoqr-interno{
       
        width: 100%;
        overflow: hidden;
        padding: 5px;
    }
}