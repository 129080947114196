.modal{
   /* position: fixed; */
   position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: auto;
    z-index: 1000;
    border-radius: 0;
    background: rgba(78, 84, 107, 0.879);

}

.modal-menu-lateral{
    border-top-left-radius: 8px;
   /* height: 100%;*/
    border: 1px solid rgb(203, 203, 203);
    background-color: #f2f2f2;
    border-bottom-left-radius: 8px;
}

.modal-btns{
    background-color: #e5e5e5;
    border-top: 1px solid rgb(173, 173, 173);
    width: 100%;
    height: 52px;
    display: flex;

    align-items: center;
    gap: 20px;
    justify-content: end;
    margin-top: 6px;
    padding: 6px;
 



}


/* -----------------------  */

.modal-titulo{


    top: 0px;
    left: 0px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
   
    border-bottom: 1px solid rgb(217, 217, 217);

    height: 42px;
    min-height: 42px;
    width: 100%;


}


.close{
    background-color: #F65835;
    border: 0;

    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    border-top-left-radius: 3px;
    border-end-end-radius: 5px;
    height: 100%;
}

.close svg{
    margin-right: 4px;
    
}


.close_new{
  
    border: 0;

    color: #a41c1c;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    border-radius:  5px;
    height: 82%;
    margin-right: 5px;
}

.close_new svg{
    margin-left: 3px;
    margin-right: -4px;
    
}

.close_new:hover{
    background-color: #dad7e1;
}

.salva_new{
  
    border: 0;

    color: #1ca427;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    border-radius:  5px;
    height: 82%;
    margin-right: 5px;
    width: 130px;
}

.salva_new svg{
    margin-left: 3px;
    margin-right: -4px;
    
}

.salva_new:hover{
    background-color: #d7e1db;
}

.extra_new{
  
    border: 0;

    color: #3271af;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    border-radius:  5px;
    height: 82%;
    margin-right: 5px;
    width: 130px;
}

.extra_new svg{
    margin-left: 3px;
    margin-right: -4px;
    
}

.extra_new:hover{
    background-color: #d7dce1;
}

.modal-titulo h2{
    margin: 0;
    padding: 0;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    
    color: rgba(0,0,0,.75);
    font-size: 15px;

}

/* -----------------------  */

.row{  
    width: 100%;
    height: 100%;
}

.row_flex{  
    padding-top: 6px;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 5px;
}

.modal p{
/*    padding-top: .5em;
   white-space: pre-wrap; */
}

/* ------------------------------ */

.modal-box-small{
    display: inline-flex;
    flex-wrap: nowrap;
    width: 30%; 
   /* border: 1px solid #ff2525; */
}

.modal-box-small:first-child { margin-right: 2% !important; }
.modal-box-small:last-child { margin-left: 2% !important; }

.modal-box-medio{
    display: inline-flex;
    flex-wrap: nowrap;
    width: 48%; 
   /* border: 1px solid #ff2525; */
}

.modal-box-medio:first-child { margin-right: 2% !important; }

.modal-box-full{
  /*  border: 1px solid #4f35f6; */
    display: flex;
    width: 100%;

}

.modal-content{

 
    height: 100%;
    width: 100%;

}




.modal-container{
    
    /*
    padding-top: 40px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    */

    text-align: left;

    position: relative;
    outline: 0;
    width: auto;
    min-width: 350px;

    height: auto;
    display: flex;
    vertical-align: middle;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 30px auto;
    background-color: #ffffff;
    color: #333333;
    
    transition: all 0.6s;


    animation-name: anim-modal;
    animation-duration: .28s;
    animation-fill-mode: forwards; 
}

@keyframes anim-modal {
    from {opacity: 0;} 
    to{opacity: 1;}

}

@media screen and (max-width: 560px) {
    .modal{
        
    }

    .modal-content{
        overflow: auto;
        overflow-x: hidden;
      
    }
    .modal-container{
       /* border: 3px solid #7c35f6; */

        margin: 0 auto;
        margin-top: -35px;
        width: 98% !important;
        min-width:98% !important;
 
  
    }
    
   
}